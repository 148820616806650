<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">عرض بيانات الطلب</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row" id="print-section" style="direction: rtl;">
              <div class="col-md-12">
                <table class="table table-bordered"  style="direction: rtl;">
                    <tr>
                        <th>اسم العميل</th>
                        <td>{{ order.client.username }}</td>
                    </tr>
                    <tr >
                      <th>رقم الهاتف</th>                   
                      <td>{{ order.client.mobile }}</td>
                    </tr>
                    <tr>
                      <th>الحالة</th>
                      <td>{{ order.status.name }}</td>
                    </tr>
                    <tr v-if="order.note">
                      <th>ملاحظات</th>
                      <td>{{ order.note }}</td>
                    </tr>
                    <tr >
                      <th>التكلفة</th>
                      <td >{{ order.total | number }} QAR</td>
                    </tr>
                </table>
              </div>
              <div class="col-md-12" v-if="order.products.length > 0">
                <h3>التفاصيل</h3>
                <table class="table table-bordered"  style="direction: rtl;">
                    <thead>
                    <tr>
                        <th>المنتج</th>
                        <th>الحجم</th>
                        <th>اللون</th>
                        <th>الكمية</th>
                        <th>السعر</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(product, index) in order.products" v-bind:key="index">
                        <td>{{ product.product.name }}</td>
                        <td>{{ product.size.name }}</td>
                        <td>{{ product.color.name }}</td>
                        <td>{{ product.quntity }}</td>
                        <td>{{ product.price | number }} QAR</td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <CButton color="warning" @click="goBack">رجوع</CButton>
            <CButton color="success" @click="print">طباعة</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "ShowOrder",
  data() {
    return {
      order: [],
      image: "",
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}orders/${this.$route.params.id}`)
      .then((response) => {
        console.log("response", response.data.data);
        this.order = response.data.data;
        console.log(this.order);
      });
  },
  methods: {
    goBack() {
      this.ordersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/orders" });
    },
    print() {
      this.$htmlToPaper('print-section');
    }
  },
};
</script>

<style scoped>
th {
    background-color: #3c4b64;
    color: #fff;
}
</style>
